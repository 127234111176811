import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import HomeIcon from '@material-ui/icons/HomeRounded'
import Typography from '@material-ui/core/Typography'

import Link from '../General/Link'

const useStyles = makeStyles((theme) => ({
    box: {
        alignSelf: 'stretch',
        background: theme.palette.primary.main,
        padding: theme.spacing(1),
        color: '#fff',
    },
    icon: {
        borderStyle: 'solid',
        borderColor: '#fff',
        borderRadius: '50%',
        borderWidth: 2,
        padding: theme.spacing(0.25),
    },
}))

const Evaluation = () => {
    const classes = useStyles()

    return (
        <Link href={'/real-estate/sell/evaluation'}>
            {/* <ButtonBase>
                <div className={classes.box}>
                    <HomeIcon color="inherit" className={classes.icon} />
                    <Typography variant="subtitle2" color="inherit">
                        What&apos;s My
                        <br />
                        Home Worth?
                    </Typography>
                </div>
            </ButtonBase> */}
        </Link>
    )
}

export default Evaluation
