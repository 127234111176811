import React from 'react'

import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import Box from '@material-ui/core/Box';
// import { Button } from '@material-ui/core'
// import Toolbar from '@material-ui/core/Toolbar'

import { ElevateOnScroll, ScrollTop } from './Scroll'
import Logo from './Logo'

import SimpleLoading from '../Menu/SimpleLoading'
import MainMenu from '../Menu/MainMenu'
import Evaluation from '../Menu/Evaluation'
import Footer from '../Footer/Footer'
import Link from '../General/Link'
// import CommonLinks from '../Footer/CommonLinks'
// import Band from '../Menu/Band'

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        height: '100%',
        width: '100%!Important'
    },
    spacer: {
        flexGrow: 1,
    },
    toolbar: {
        padding: theme.spacing(2, 0),
        marginRight: theme.spacing(1),  
        marginLeft: theme.spacing(2),  
    },
    authButton: {
        color: 'error',
        marginLeft: theme.spacing(1),
    },    
    appBar: {
        backgroundColor: '#003366',
        width: '100%!Important',
        [theme.breakpoints.down('md')]: {
            padding: '15px',
        },    
    },
    container: {
        width: '100%!Important',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
        },
    },
    
}))

// noinspection JSCheckFunctionSignatures
const AuthBox = dynamic(() => import('../Auth/AuthBoxBand'), {
    loading: SimpleLoading,
    ssr: false,
})


const Layout = ({ children, from }) => {
    const classes = useStyles()
    const router = useRouter()

    const hasFrom = !!router.query.from_name && !!router.query.from_url

    const goToLogin = () => {
        // perform some action if needed
        router.push('/auth/login');
      };
      
      const goToRegister = () => {
        // perform some action if needed
        router.push('/auth/register');
      };
      

    return (
        <>
            <div className={classes.root} width='100%!important'>
                
                <Hidden mdDown implementation="js">
                    {/* <Band /> */}
                </Hidden>
                <ElevateOnScroll  >
                <AppBar position="sticky" color="inherit" elevation={0} className={classes.appBar}>
                <Container className={classes.container} maxWidth={false}>
                    <Hidden mdDown implementation="js">
                        <div style={{position: 'fixed', top: 0, left: 0, height: '100vh', width: '10px', zIndex: 1000}}>
                            <img src="/images/gline.png" style={{objectFit: 'cover', height: '100%', width: '100%'}} alt="line"/>
                        </div>
                    </Hidden>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Hidden smDown implementation="js">
                                <Box display="flex" alignItems="center">
                                    <Logo from={from} style={{marginTop: '20px'}}/>
                                    <Box marginLeft={2}>
                                        <MainMenu
                                        route={router && router.pathname}
                                        from={from}
                                        variant="inline"  // Example prop for different behavior
                                        />
                                    </Box>
                                </Box>
                            </Hidden>

                            <Hidden mdUp implementation="js">
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                    <Logo from={from} style={{marginTop: '20px'}}/>
                                    <Box marginLeft={2}>
                                        <MainMenu
                                        route={router && router.pathname}
                                        from={from}
                                        variant="inline"  // Example prop for different behavior
                                        />
                                    </Box>
                                </Box>
                            </Hidden>

                            <Hidden smDown implementation="js">
                                <AuthBox></AuthBox>
                                {/* <Box display="flex" alignItems="center">
                                    <Button className="authButton" style={{color: '#ECC490', fontSize: 18}} onClick={goToLogin}>Login</Button>
                                    <Button className="auth-button" style={{color: '#ECC490', fontSize: 18}} onClick={goToRegister}>Signup</Button>
                                </Box> */}
                            </Hidden>
                        </Box>
                    <Evaluation />
                </Container>

                    </AppBar>
                </ElevateOnScroll>
                <Divider />
                {hasFrom && (
                    <Alert severity="warning">
                        <Link
                            href={router.query.from_url}
                            muiProps={{ underline: 'always' }}
                        >
                            Go back to {router.query.from_name}.
                        </Link>
                    </Alert>
                )}
                {children}
                {/* <CommonLinks /> */}
                <Footer />
            </div>
            <ScrollTop>
                <Fab aria-label="Scroll To Top" style={{backgroundColor:'#D5A77C'}} size="small">
                    <ArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    )
}

export default Layout
