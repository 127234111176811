import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

import FacebookIcon from '../Icons/FacebookIcon'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        color: ({ light }) => (light ? '#fff' : theme.palette.primary.main),
        cursor: 'pointer',
    },
    link: {
        height: 24,
        display: 'flex',
        alignItems: 'center',
    },
}))

const SocialIcons = ({ light }) => {
    const classes = useStyles({ light })

    return (
        <>
            <Link
                className={classes.link}
                href="https://facebook.com/RastiTeam"
                target="_blank"
                rel="nofollow noopener"
            >
                <FacebookIcon className={classes.icon} />
                @RastiTeam
            </Link>
        </>
    )
}

export default SocialIcons
