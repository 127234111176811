import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'

import Drawer from './Drawer'
import BuyDropdown from './SubMenu/BuyDropdown'
import SellDropdown from './SubMenu/SellDropdown'
import TeamDropdown from './SubMenu/TeamDropdown'
import ThemeToggleButton from './ThemeToggleButton'
import ProjectsDropdown from './SubMenu/ProjectsDropDown'
import BlogsDropdown from './SubMenu/BlogsDropdown'
import RealEstateDropdown from './SubMenu/RealEstateDropdown'

const MainMenu = ({ from }) => {
    const [drawer, setDrawer] = useState(false)
    const closeDrawer = () => setDrawer(false)
    const openDrawer = () => setDrawer(true)

    return (
        <>
            <Hidden mdUp implementation="js">
                {/* <ThemeToggleButton /> */}
                <IconButton onClick={openDrawer}>
                    <MenuIcon style={{color:'#C9996E'}} />
                </IconButton>
            </Hidden>
            <Hidden smDown implementation="js">
                <RealEstateDropdown from={from}/>
                {/* <ProjectsDropdown from={from}/> */}
                
                <BuyDropdown from={from} />
                <BlogsDropdown from={from}/>
                {/* <SellDropdown from={from} /> */}
                <TeamDropdown from={from} />
            </Hidden>
            <Drawer open={drawer} onClose={closeDrawer} />
        </>
    )
}

export default MainMenu
