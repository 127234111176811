import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    icon: {
        '&:hover': {
            color: ({ color }) => `${color} !important`,
        },
    },
}))

const Icon = ({ className, color, children, ...props }) => {
    const classes = useStyles({ color })
    return (
        <SvgIcon className={clsx(className, classes.icon)} {...props}>
            {children}
        </SvgIcon>
    )
}

export default Icon
