import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Link from '../General/Link'

const LINKS = [
    {
        title: 'MLS Search',
        href: '/real-estate/buy/mls-search',
    },
    {
        title: 'Buy Pre-construction',
        href: '/project/category/pre-construction',
    },
    {
        title: 'Buy Land',
        href: '/project/category/land',
    },

    {
        title: 'Buy Assignments',
        href: '/project/category/assignments',
    },

    {
        title: 'Buy Power-of-Sale',
        href: '/project/category/power-of-sale',
    },

    {
        title: 'Property Evaluation',
        href: '/real-estate/sell/evaluation',
    },
    // {
    //     title: 'Shop Real Estate',
    //     href: '/real-estate/buy/mls-search',
    // },
    // {
    //     title: 'Sell My Property',
    //     href: '/real-estate/sell/services',
    // },
    {
        title: 'Off-Market Listings',
        href: '/real-estate/buy/off-market',
    },
    {
        title: 'Bayshire Team',
        href: '/team/about-us',
    },
]

const useStyles = makeStyles((theme) => ({
    link: {
        '&:hover': {
            color: '#D9A06E',
        },
    },
}))

const FooterMenu = () => {
    const classes = useStyles()

    return LINKS.map((l) => (
        <Link href={l.href} key={l.href}>
            <Typography
                variant="subtitle2"
                component="p"
                color="inherit"
                className={classes.link}
            >
                {l.title}
            </Typography>
        </Link>
    ))
}

export default FooterMenu
