import React from 'react'
import Image from 'next/image'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Link from '../General/Link'
import FooterMenu from './FooterMenu'
import SocialIcons from '../General/SocialIcons'
import ADDRESSES from '../../helpers/Strings'

import MRTLogo from '../../../public/images/logo/mase-rasti-team-logo-compact-new-inverted.png'
import NavbarLogo from '../../../public/images/logo/footer_log_B.png'
// const CONTACTS = ['Direct: (647)-878-2221', 'Mase@RastiTeam.com']
const CONTACTS = ['(647)-878-2221']

const useStyles = makeStyles((theme) => ({
    link: {
        '&:hover': {
            color: '#D9A06E',
        },
    },
    logo: {
        marginBottom: theme.spacing(2),
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    root: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    social: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
}))

const FooterContent = () => {
    const classes = useStyles()

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} sm={8} md={4}>
                <div className={classes.logoContainer}>
                    <Link href="/">
                        <span className={classes.logo}>
                            <Image
                                src={NavbarLogo}
                                alt="Bayshire Real Estate - North Toronto and Mississauga realtor"
                                placeholder="blur"
                                width={120}
                                height={100}
                            />
                        </span>
                    </Link>
                </div>
                <Typography variant="body1" align="center">
                    Built on knowledge, experience, latest digital technologies
                    with a traditional emphasis on attentiveness, thoughtfulness
                    and service.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
                <Typography variant="h5" component="p" gutterBottom>
                    <b style={{color: '#DDB587'}}>Menu</b>
                </Typography>
                <FooterMenu />
            </Grid>
            <Grid item xs={12} md={2}>
                <Typography variant="h5" component="p" gutterBottom>
                    <b style={{color: '#DDB587'}}>Contact</b>
                </Typography>
                {CONTACTS.map((contact, i) => (
                    <Typography variant="body1" gutterBottom key={i}>
                        {contact}
                    </Typography>
                ))}
                <Link href='/team/contact-us' key='/team/contact-us'>
                    <Typography
                        variant="subtitle2"
                        component="p"
                        color="inherit"
                        className={classes.link}
                    >
                        Get in Touch
                    </Typography>
                </Link>
                {/* <div className={classes.social}>
                    <SocialIcons light />
                </div> */}
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h5" component="p" gutterBottom>
                    <b style={{color: '#DDB587'}}>Addresses</b>
                </Typography>
                {ADDRESSES.map((address, i) => (
                    <Typography variant="body1" gutterBottom key={i}>
                        {address}
                    </Typography>
                ))}
            </Grid>
        </Grid>
    )
}

export default FooterContent
