import React from 'react'
import Image from 'next/image'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'

import FooterContent from './FooterContent'
import Link from '../General/Link'

import RemaxLogo from '../../../public/images/logo/Remax.png'

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#003366',
        paddingTop: theme.spacing(5),
    },
    copyright: {
        color: theme.palette.grey[500],
        backgroundColor: '#003366',
        flexGrow: 1,
        margin: theme.spacing(0, 1),
    },
    divider: {
        margin: theme.spacing(4, 0),
        backgroundColor: theme.palette.grey[900],
    },
    link: {
        marginLeft: theme.spacing(3),
    },
    remaxBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
    },
    band: {
        backgroundColor: '#003366',
        padding: theme.spacing(1),
    },
}))

const Footer = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Container>
                <FooterContent />
            </Container>
            <Divider className={classes.divider} />
            <div className={classes.remaxBox}>
                <MuiLink
                    href="https://www.remaxhallmark.com/"
                    target="_blank"
                    rel="noopener"
                >
                    <Image
                        src={RemaxLogo}
                        alt="Remax Hallmark Brokerage"
                        placeholder="blur"
                        height={75}
                        width={240}
                    />
                </MuiLink>
            </div>
            <div className={classes.band}>
                <Typography
                    variant="body1"
                    align="center"
                    className={classes.copyright}
                    gutterBottom
                >
                    Copyright &copy; 2023 Bayshire Realty. 
                    All rights reserved.
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    className={classes.copyright}
                >
                    <Link href={'/legal/terms-of-use'}>Terms of Use</Link>
                    {' - '}
                    <Link href={'/legal/privacy-policy'}>Privacy Policy</Link>
                </Typography>
                {/* <Typography
                    variant="body2"
                    align="center"
                    className={classes.copyright}
                >
                    V11
                </Typography> */}
            </div>
        </div>
    )
}

export default Footer
